import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  changelogTitle: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  changelogSubtitle: {
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  changelogCode: {
    background: "rgb(244, 245, 247)",
    color: "rgb(23, 43, 77)",
    display: "inline",
  },
  changelogPre: {
    background: "rgb(244, 245, 247)",
    color: "rgb(23, 43, 77)",
    display: "block",
    padding: "10px 30px",
    margin: "0",
    // overflow: "scroll",
  },
}));
