import { FC, useState } from "react";

import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";

import { signOut } from "aws-amplify/auth";
import { useStyles } from "./topBar.styles";
import apexBeamLogo from "assets/ApexLogo_BlueWhite_long.svg";

interface ITopBarProps {
  heading?: string;
  loggedIn?: boolean;
  subHeading?: string;
}

export const TopBar: FC<ITopBarProps> = ({ heading, loggedIn, subHeading }) => {
  const classes = useStyles();

  const [profileMenuAnchor, setProfileMenuAnchor] = useState<Element | null>(
    null,
  );
  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setProfileMenuAnchor(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  const handleLogout = () => {
    signOut();
    window.location.reload();
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.appBarContent}>
        <img
          className={classes.logo}
          src={apexBeamLogo}
          alt="apexanalytix cyberrisk"
        />
        <div className={classes.headingContainer}>
          {heading && (
            <Typography variant="subtitle1" noWrap className={classes.heading}>
              {heading}
            </Typography>
          )}
          {subHeading && (
            <Typography variant="body2" className={classes.subHeading}>
              {subHeading}
            </Typography>
          )}
        </div>

        {loggedIn && (
          <>
            <IconButton
              aria-label="open profile menu"
              onClick={handleProfileMenuOpen}
              edge="start"
              className={classes.profileButton}
              color="inherit"
            >
              <Person />
            </IconButton>

            <Menu
              anchorEl={profileMenuAnchor}
              keepMounted
              open={Boolean(profileMenuAnchor)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
