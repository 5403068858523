import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: `${theme.palette.primary.main}`,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
    overflow: "hidden",
    //paddingBottom: theme.spacing(),
    //paddingTop: theme.spacing(),
    marginTop: -theme.spacing(),
    marginBottom: -theme.spacing(),
    width: "100%",
  },
  headingContainer: {},
  heading: {
    lineHeight: 1,
    marginLeft: 10,
    fontFamily: "sans-serif",
    color: "white",
  },
  subHeading: {
    color: theme.palette.primary.light,
    lineHeight: 1.2,
    paddingTop: theme.spacing(0.75),
  },
  appBarContent: {
    paddingLeft: 0,
  },
  profileButton: {
    marginLeft: "auto",
  },
  logo: {
    paddingLeft: 50,
    width: 250,
  },
}));
