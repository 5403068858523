//import { Buffer } from "buffer";
//import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Amplify } from "aws-amplify";
import { ThemeProvider } from "@material-ui/core";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { theme } from "theme/mui-theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsconfig);

//const nonce = Buffer.from(crypto.randomUUID()).toString("base64");
const nonce = "OGVkMzBiNjctODcyZi00MmFjLThmNmEtZmEwMmNmMDhkNjVi";
/*
const csp = `
  default-src 'none';
  connect-src 'self' *.api.darkbeam.com https://cognito-idp.eu-west-2.amazonaws.com;
  script-src 'self';
  font-src 'self';
  manifest-src 'self';
  style-src 'self' 'nonce-${nonce}';
  img-src 'self' data:;
  base-uri 'self';
  object-src 'none';
  form-action 'self';
  upgrade-insecure-requests
`;
const cspTag = document.createElement("META");
cspTag.setAttribute("http-equiv", "Content-Security-Policy");
cspTag.setAttribute("content", csp);
*/
const cspNonceTag = document.createElement("META");
cspNonceTag.setAttribute("property", "csp-nonce");
cspNonceTag.setAttribute("content", nonce);
document.head.prepend(cspNonceTag);
//document.head.prepend(cspTag);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const cache = createCache({
  key: "darkbeam-key",
  nonce: nonce,
  prepend: true,
});

root.render(
  //<React.StrictMode>
  <CacheProvider value={cache}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </CacheProvider>,
  //</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
