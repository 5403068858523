// state
import { Observer } from "mobx-react";
import React from "react";

// replaces the InfoContainer component
// https://github.com/swagger-api/swagger-ui/blob/dd3afdc45656bda2a64ae6a7f9bdad006ea98149/src/core/components/layouts/base.jsx

// Note: this is called not as a component, but as a function within a class component. Do
// *not* make this a component, and do *not* use hooks or anything similar in it.
export const InfoReplacement = () => {
  return <Observer>{() => <h1>Scoreboard V3 API</h1>}</Observer>;
};

export const InfoReplacementV4 = () => {
  return <Observer>{() => <h1>Cyber Risk V4 API</h1>}</Observer>;
};
