import { InfoReplacement, InfoReplacementV4 } from "./swaggerLayout";

export const DisableTryItOutPlugin = () => {
  return {
    statePlugins: {
      spec: {
        wrapSelectors: {
          allowTryItOutFor: () => () => true,
        },
      },
    },
  };
};

// Create the plugin that provides our layout component
export const SwaggerLayoutPlugin = {
  components: { InfoContainer: InfoReplacement },
};

export const SwaggerLayoutPluginV4 = {
  components: { InfoContainer: InfoReplacementV4 },
};
