import { createTheme } from "@material-ui/core/styles";
import { hexToRGBA } from "./theme.utils";

export const themeColours = {
  primary: {
    heliotropeGrey: "#9A8C98",
    independence: "#4A4E69",
    spaceCadet: "#22223B",
  },
  secondary: {
    aquamarine: "#80FFDB",
    blueMunsell: "#168AAD",
    mediumPurple: "#9381FF",
    palePurple: "#FEEAFA",
  },
  black: "#000000",
  greys: {
    900: "#333333",
    800: "",
    700: "",
    600: "",
    500: "",
    400: "",
  },
  white: "#FFFFFF",
};

const fontFamily = `'Gilroy', Helvetica, Arial, sans-serif`;

const headingBaseStyles = {
  color: themeColours.primary.independence,
  fontSize: "24px",
  fontWeight: 700,
  letterSpacing: "-1px",
};

export const theme = createTheme({
  palette: {
    primary: {
      contrastText: themeColours.white,
      dark: themeColours.primary.spaceCadet,
      main: themeColours.primary.independence,
      light: themeColours.primary.heliotropeGrey,
    },
    secondary: {
      contrastText: themeColours.white,
      dark: themeColours.secondary.blueMunsell,
      main: themeColours.secondary.mediumPurple,
      light: themeColours.secondary.aquamarine,
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: fontFamily,
    fontWeightRegular: 300,
    fontWeightLight: 300,
    fontWeightBold: 700,
    fontWeightMedium: 700,
    h1: { ...headingBaseStyles },
    h2: { ...headingBaseStyles },
    h3: { ...headingBaseStyles },
    h4: { ...headingBaseStyles },
    h5: { ...headingBaseStyles },
    h6: { ...headingBaseStyles },
    subtitle1: {
      ...headingBaseStyles,
      fontSize: "24px",
    },
    subtitle2: {
      ...headingBaseStyles,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiPopover: {
      elevation: 3,
    },
    MuiSelect: {
      variant: "outlined",
    },
    MuiTextField: {
      color: "primary",
      variant: "outlined",
    },
    MuiTypography: {
      variantMapping: {
        subtitle1: "h2",
      },
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: hexToRGBA(themeColours.primary.spaceCadet, 0.4),
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
        letterSpacing: "0",
        textTransform: "none",
      },
      containedPrimary: {
        boxShadow: "none",
      },
    },
    MuiFab: {
      root: {
        boxShadow: "none",
      },
    },
    MuiTableCell: {
      root: {
        verticalAlign: "top",
      },
    },
  },
});
