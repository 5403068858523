import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 130,
  },
  versionButton: {
    marginLeft: "auto",
    boxSizing: "border-box",
    border: "2px solid #4A4E69",
    color: "rgb(255, 255, 255)",
    backgroundColor: "#4A4E69",
    fontFamily: "Gilroy-SemiBold, helvetica, arial, sans-serif",
    fontSize: "1.05rem",
    lineHeight: 1,
    padding: "1rem",
    pointerEvents: "auto",
    opacity: 1,
    textDecoration: "none",
    position: "fixed",
    right: "0px",
    transition: "width 0.3s ease 0s",
    zIndex: 100,
    width: "150px",
    marginTop: "-20px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(148, 123, 243)",
      border: "2px solid transparent",
      color: "rgb(255, 255, 255)",
      transition: "width 0.3s ease 0s",
      width: "170px",
    },
  },
  changelog: {
    //display: "none",
    width: 0,
    position: "fixed",
    right: 0,
    top: "91px",
    backgroundColor: "white",
    height: "calc(100% - 134px)",
    zIndex: 200,
    overflow: "scroll",
    transition: "width 0.2s linear 0s",
    padding: 0,
    border: "1px solid rgba(59,65,81,.3)",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.15)",
    color: "#3b4151",
  },
  closeButton: {
    position: "inherit",
    right: 25,
    marginTop: -10,
  },
  changelogTitle: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
}));
