import { Typography } from "@material-ui/core";
import { FC } from "react";
import { useStyles } from "./changelog.styles";

export const ChangeLog: FC<any> = () => {
  const classes = useStyles();

  const json = `{
    "error": false,
    "data": {
      // report data
     },
    "message": "✔"
}`;

  return (
    <>
      <Typography className={classes.changelogTitle}>
        Scoreboard V4 Changelog
      </Typography>
      <p>
        The key feature of the new version is the transition from a synchronous
        scan to an asynchronous one, which brings more advantages and
        improvements.
      </p>

      <Typography className={classes.changelogSubtitle}>
        Benefits of V4
      </Typography>
      <ul>
        <li>
          Will provide more consistent and accurate results for domain scoring.
        </li>
        <li>
          Asynchronous queries and increased gateway timeouts overcomes issues
          with slow to respond internet sites.
        </li>
        <li>
          The Cyber Risk architecture has be enhanced to provide improved
          performance and higher throughput of domains.
        </li>
        <li>
          The new architecture will allow for the addition of more endpoints as
          the tests carried out by Cyber Risk expand. It also allows the
          inclusion of cyber threat endpoints.
        </li>
        <li>General security improvements.</li>
      </ul>

      <Typography className={classes.changelogSubtitle}>
        Migrating from V3 to V4:
      </Typography>

      <p>Change body response for following endpoints.</p>
      <p>
        - in v3 report body was placed in root of the response now it's placed
        inside <code className={classes.changelogCode}> data </code>
        key.
      </p>

      <ul>
        <li>
          <span className={classes.changelogCode}>
            <code>POST /report</code>
          </span>
        </li>
        <li>
          <span className={classes.changelogCode}>
            <code>GET /report/{"{link_id}"}</code>
          </span>
        </li>
        <li>
          <span className={classes.changelogCode}>
            <code>PATCH /report/{"{link_id}"}/rescan-failures</code>
          </span>
        </li>
      </ul>

      <pre className={classes.changelogPre}>{json}</pre>
      <p></p>

      <code className={classes.changelogCode}>{""}</code>

      <Typography className={classes.changelogSubtitle}>
        Modifications in existing endpoints:
      </Typography>
      <ul>
        <li>
          <span className={classes.changelogCode}>
            <code>POST /report</code>
          </span>
        </li>
        <li>
          <span className={classes.changelogCode}>
            <code>GET /report/{"{link_id}"}</code>
          </span>
        </li>
      </ul>
      <span>New query params added: </span>
      <ul>
        <li>
          <p>
            <code className={classes.changelogCode}>disable_wait</code> - (false
            by default) - if value is <b>false</b> the request will wait (max
            wait time 15 seconds) for scan to finish, if value is <b>true</b>{" "}
            the request will not wait for the scan to finish it will return the
            report ID and status (ID can be used with GET /report/{"{link_id} "}
            to return the report).
          </p>
        </li>
        <li>
          <p>
            <code className={classes.changelogCode}>disable_cache</code> -
            (false by default) - if value is <b>false</b> the report will be
            cached 15 min witch will improve the response time of all ready
            scanned reports, if value is <b>true</b> every report will be
            rescanned from the beginning.
          </p>
        </li>
        <li>
          <p>
            <code className={classes.changelogCode}>expire_at</code> - the
            default expiration date is 1 month from when the report was
            completed this date can be changed <br />
            <code className={classes.changelogCode}>
              "expire_at": "2024-02-11T17:45:56+02:00".
            </code>
          </p>
        </li>
      </ul>

      <p>
        New response body: - to keep a symmetry for all endpoints the report
        will be stored inside
        <code className={classes.changelogCode}> data </code>
        key, the report structure will remain the same.
      </p>

      <Typography className={classes.changelogSubtitle}>
        Removing the following endpoints:
      </Typography>

      <ul>
        <li>
          <span className={classes.changelogCode}>
            <code>GET /report</code>
          </span>
        </li>
      </ul>

      <p>
        This endpoint played the same role as the POST one, the single
        difference was that it returned less information in the response body.
      </p>

      <Typography className={classes.changelogSubtitle}>
        Added the following endpoints:
      </Typography>

      <ul>
        <li>
          <span className={classes.changelogCode}>
            <code>PATCH /report/{"{link_id}"}/rescan-failures</code>
          </span>
        </li>
      </ul>

      <p>
        This endpoint will play a role to rescan the failed section of a report.
      </p>
    </>
  );
};
