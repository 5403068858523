import React, { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { TopBar } from "components/topBar";
import { getCurrentUser, AuthUser } from "aws-amplify/auth";
import {
  SwaggerLayoutPlugin,
  //SwaggerLayoutPluginV4,
} from "components/swagger/plugins";
import { Button, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useStyles } from "./App.styles";
import { ChangeLog } from "./components/changelog/index";

const dev = process.env.NODE_ENV === "development";

const App = () => {
  const classes = useStyles();
  var scoreboardV3 = require("./scoreboardV3.json");
  var scoreboardV4 = require("./scoreboardV4.json");

  const [currentUser, setCurrentUser] = useState<AuthUser>();
  const [viewV3, setV3] = useState(false);
  const [viewChangeLog, setChangeLog] = useState(false);

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      try {
        const user = await getCurrentUser();
        setCurrentUser(user);
      } catch (err) {
        console.info(err);
      }
    };
    getAuthenticatedUser();
  }, []);

  return (
    <div>
      <TopBar heading="Cyber Risk" loggedIn={!!currentUser} />
      <Button className={classes.versionButton} onClick={() => setV3(!viewV3)}>
        API {!viewV3 ? "V3" : "v4"}
      </Button>
      {!viewV3 && (
        <Button
          className={classes.versionButton}
          onClick={() => setChangeLog(true)}
          style={{ marginTop: "40px" }}
        >
          Changelog
        </Button>
      )}
      <div
        className={classes.changelog}
        style={
          viewChangeLog ? { width: 500, padding: 20 } : { width: 0, padding: 0 }
        }
      >
        {viewChangeLog && (
          <IconButton
            className={classes.closeButton}
            size={"small"}
            onClick={() => setChangeLog(false)}
          >
            <Close />
          </IconButton>
        )}
        <ChangeLog></ChangeLog>
      </div>
      {!viewV3 && (
        <div className={classes.content}>
          <SwaggerUI
            defaultModelsExpandDepth={-1}
            spec={scoreboardV4}
            docExpansion="none"
            //tryItOutEnabled={true}
          />
        </div>
      )}
      {viewV3 && (
        <div className={classes.content}>
          <SwaggerUI spec={scoreboardV3} plugins={[SwaggerLayoutPlugin]} />
        </div>
      )}
    </div>
  );
};

export default dev ? App : withAuthenticator(App, { hideSignUp: true });
